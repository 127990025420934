@use 'src/css/breakpoint.scss' as *;

.wrap {
  margin-bottom: 1px;

  &:last-child {
    margin-bottom: unset;
  }
}

.arrowDown {
  color: var(--secondary-text-color);
  min-width: 20px;
  width: 20px;
  height: 12px;
  transition: transform var(--transition);
  margin-left: 16px;

  @include maxMediaWidth(lg) {
    min-width: 15px;
    width: 15px;
    height: 9px;
  }
}

.wrapTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 50px;
  background: var(--color-primary-alpha-10);
  cursor: pointer;

  &.active .arrowDown {
    transform: rotate(180deg);
  }

  &.active,
  &:hover {
    background: var(--primary-button-bg-hover-dark-color);

    .title,
    .arrowDown {
      color: var(--primary-button-hover-dark-color);
    }
  }

  @include maxMediaWidth(lg) {
    padding: 13px 20px 14px;
  }

  @include maxMediaWidth(sm) {
    padding: 13px 16px 14px;
  }
}

.title {
  margin: 0;
  font: var(--font-h7);
  color: var(--main-title-color);

  @include maxMediaWidth(lg) {
    font: var(--font-h5-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h8-sm);
  }
}

.contentWrapper {
  color: var(--secondary-text-color);
  font: var(--font-h9);

  .content {
    padding: 19px 0 30px;

    p {
      margin: 0;
    }

    a {
      color: var(--link-dark-color);
      text-decoration-line: none;

      &:hover {
        color: var(--accented-text-dark-color);
      }
    }

    ul,
    ol {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 32px 0;
      padding-left: 16px;

      li {
        position: relative;
        margin-left: 15px;
      }

      @include maxMediaWidth(lg) {
        gap: 12px;
      }

      @include maxMediaWidth(sm) {
        margin: 12px 0;
        padding-left: 10px;
      }
    }

    @include maxMediaWidth(lg) {
      padding: 15px 16px 25px;
    }
  }
}
