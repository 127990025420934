@use 'src/css/breakpoint.scss' as *;

.content {
  column-count: 2;
  column-gap: 30px;

  &,
  p {
    font: var(--font-h8);
    color: var(--secondary-text-color);
    margin: 0;

    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }

  @include maxMediaWidth(lg) {
    column-count: 1;
    gap: 26px;
    row-gap: 30px;

    p {
      font: var(--font-h9-sm);
    }
  }
}
