.walletAddressContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
}

.walletAddressLabelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.walletAddressLabel {
  font: var(--font-h11);
}

.walletAddressInput {
  color: var(--color-foreground);
  font: var(--font-h9);
  padding: 20px 14px;
  border: none;
  border-radius: 4px;
  background: var(--color-primary-alpha-10);

  &Error {
    background: var(--color-warning-alpha-20);
  }
}
