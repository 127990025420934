@use 'src/css/breakpoint.scss' as *;

.indexSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px;

    @include maxMediaWidth(lg) {
      padding: 30px 55px;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 15px;
    }
  }
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.contentWrapper {
  flex: 1 1 50%;

  .title {
    padding-bottom: 15px;
  }

  .rawHtml {
    &,
    p {
      font: var(--font-h8);
      color: var(--secondary-text-color);
      margin: 0;

      @include maxMediaWidth(lg) {
        font: var(--font-h9-sm);
      }
    }
  }

  h2 {
    font: var(--font-h6);
    margin: 32px 0 16px;
    white-space: normal;
    color: var(--secondary-text-color);

    @include maxMediaWidth(lg) {
      font: var(--font-h6-md);
      margin-top: 32px;
    }

    @include maxMediaWidth(sm) {
      font: var(--font-h6-sm);
      margin-top: 24px;
    }
  }

  a {
    color: var(--link-dark-color);
    text-decoration-line: none;

    &:hover {
      color: var(--accented-text-dark-color);
    }
  }

  @include maxMediaWidth(lg) {
    flex: 1 1 70%;
  }
}

.imgWrap {
  position: relative;
  flex: 1 1 50%;

  @include maxMediaWidth(lg) {
    flex: 1 1 30%;
  }

  @include maxMediaWidth(sm) {
    display: none;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    @include maxMediaWidth(lg) {
      width: 255px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: 654px;
    height: 654px;

    border-radius: 654px;
    background: var(--bg-image-gradient);
    filter: blur(50px);
    pointer-events: none;

    @include maxMediaWidth(lg) {
      width: 270px;
      height: 270px;
    }
  }
}
