.button  {
  .arrowIcon {
    font-size: 21px;
    color: var(--secondary-dark-color);
    transition: color var(--transition);
  }

  &.right .arrowIcon {
    transform: rotate(180deg);
  }
  
  &:hover .arrowIcon {
    color: var(--secondary-text-color);
  }
}
