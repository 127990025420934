.dialogOverlay {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: grid;
  place-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  outline: none;
}

.dialogPanel {
  background-color: var(--color-background-contrast);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  position: relative;

  &.primary {
    background-color: var(--color-primary-dimmed-a90);
    border: 1px solid var(--color-primary);

    .dialogCloseButton {
      width: 36px;
      height: 36px;
      padding: 4px;
      border-radius: 100%;
      background: var(--color-primary-light-a16);
      color: var(--color-foreground);
    }
  }
}

.dialogCloseButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: inherit;
  padding-right: inherit;
}

.dialogCloseButton {
  aspect-ratio: 1;
  cursor: pointer;
  color: var(--color-foreground-contrast-2);
  background: none;
  box-shadow: none;
  border: none;
}
