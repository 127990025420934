@use 'src/css/breakpoint.scss' as *;

.cardItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 267px;
  padding: 30px;
  background: linear-gradient(119deg, #142d53 -0.17%, rgba(20, 45, 83, 0) 102.29%);
  border-radius: 8px;
}

.image {
  max-height: 80px;
  aspect-ratio: 1 / 1;
  margin-bottom: 25px;

  @include maxMediaWidth(lg) {
    width: 53px;
    height: 53px;
    min-height: 53px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title,
.subTitle {
  font: var(--font-h9);
  color: var(--main-title-color);
}

.title {
  text-transform: uppercase;
  text-align: center;

  @include maxMediaWidth(lg) {
    font-size: 14px;
  }
}

.value {
  font: var(--font-button);
  color: var(--main-title-color);
  margin-top: 5px;
}
