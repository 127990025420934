.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 20px 0;
  background: var(--secondary-bg-dark-color);
  border-radius: 8px;
  overflow-y: hidden;
}

.title {
  margin: 0 0 15px;
  font: var(--font-h7);
  color: var(--main-title-color);
}

.text {
  font: var(--font-h9);
  color: var(--secondary-text-color);
  padding-bottom: 10px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;

  .image {
    position: relative;
    object-position: bottom;
    object-fit: contain;
    max-width: 100%;
  }
}
