@use 'src/css/breakpoint.scss' as *;

.wrap {
  margin: 65px auto 0;
  max-width: fit-content;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
    max-width: unset;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }
}

.titleWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  padding-bottom: 20px;

  @include maxMediaWidth(lg) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: unset;
  }

  .title {
    margin: 0;
    font: var(--font-h7);
  }

  .updateTime {
    font: var(--font-h11);
    color: var(--special-dark-color);
  }
}

.wrapSlider {
  max-width: var(--max-content-width);
  width: 100%;
  margin: 0 auto;
}

.slider {
  :global(.swiper-wrapper) {
    align-items: stretch;
  }

  :global(.swiper-slide) {
    display: flex;
    justify-content: center;
    height: auto;
  }
}

.card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
  width: 100%;
  padding: 1px;

  @include maxMediaWidth(lg) {
    gap: 6px;
  }

  @include maxMediaWidth(sm) {
    gap: 8px;
  }
}

.pagination {
  display: none;
  margin-top: 30px;

  :global(.swiper-pagination-bullet) {
    background-color: var(--secondary-dark-color);
    opacity: 0.2;
  }

  :global(.swiper-pagination-bullet-active) {
    background: var(--secondary-dark-color);
    opacity: 1;
  }

  &:global(.swiper-pagination-disabled),
  &:global(.swiper-pagination-hidden) {
    display: none;
  }

  @include maxMediaWidth(sm) {
    display: flex;
    justify-content: center;
  }
}
