@use 'src/css/breakpoint.scss' as *;

.indexSection {
  margin-top: 50px;
  margin-bottom: 50px;

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px 0;

    @include maxMediaWidth(lg) {
      padding: 30px 0;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 0;
    }
  }
}

.title {
  padding-bottom: 50px;

  @include maxMediaWidth(lg) {
    padding-bottom: 38px;
  }

  @include maxMediaWidth(sm) {
    padding-bottom: 25px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  @include maxMediaWidth(lg) {
    gap: 20px;
  }

  @include maxMediaWidth(sm) {
    gap: 8px;
  }
}
