.title {
  display: flex;
  align-items: center;
}

.opened {
  .wrapArrow {
    transform: scale(1, -1)
  }
}

.wrapArrow {
  width: 9px;
  margin-left: 5px;
}