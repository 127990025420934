@use 'src/css/breakpoint.scss' as *;

.indexSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
    padding: 0 !important;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px;

    @include maxMediaWidth(lg) {
      padding: 30px 0 !important;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 0;
    }
  }
}

.title {
  padding-bottom: 50px;

  &.withSubtitle {
    padding-bottom: 15px;
  }

  @include maxMediaWidth(lg) {
    padding: 0 55px 38px;
  }

  @include maxMediaWidth(sm) {
    padding: 0 16px 25px;

    &.withSubtitle {
      padding-bottom: 5px;
    }
  }
}

.subtitle {
  color: var(--secondary-text-color);
  font: var(--font-h9);
  margin: 0;
  padding-bottom: 50px;

  @include maxMediaWidth(lg) {
    padding: 0 55px 40px;
  }

  @include maxMediaWidth(sm) {
    padding: 0 16px 20px;
    font: var(--font-h9-sm);
  }
}
