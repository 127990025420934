.paymentMethodsContainer {
  display: grid;
  gap: 4px;
  font-size: 14px;
}

.paymentMethodsLabel {
  font: var(--font-h11);
  color: var(--color-foreground);
}

.paymentMethodsListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.paymentMethodItem {
  cursor: pointer;
  text-transform: uppercase;
  background-color: var(--color-primary-alpha-20);
  color: inherit;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  min-height: 48px;
  font-weight: 500;

  &[aria-checked='true'] {
    border: 1px solid var(--color-primary);
  }
}
