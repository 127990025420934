@use 'src/css/breakpoint.scss' as *;

.indexSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px;

    @include maxMediaWidth(lg) {
      padding: 30px 55px;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 15px;
    }
  }
}

.titleWrapper {
  padding-bottom: 15px;
}

.content {
  column-count: 1;
  column-gap: 30px;

  &,
  p {
    font: var(--font-h8);
    color: var(--secondary-text-color);
    margin: 0;

    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }

  a {
    color: var(--link-dark-color);
    text-decoration-line: none;

    &:hover {
      color: var(--accented-text-dark-color);
    }
  }

  @include maxMediaWidth(lg) {
    column-count: 1;
    gap: 26px;
    row-gap: 30px;

    p {
      font: var(--font-h9-sm);
    }
  }
}

.showMoreButton {
  margin-top: 40px;
}
