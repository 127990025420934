@use 'src/css/breakpoint.scss' as *;

.modal {
  display: grid;
  gap: 24px;
  width: 100%;
  padding: 32px;

  @include maxMediaWidth(sm) {
    padding: 44px 0 0 0;
  }
}

.title {
  font: var(--font-h5);
  color: var(--color-foreground);
  text-align: center;

  @include maxMediaWidth(sm) {
    font: var(--font-h6);
  }
}

.walletsWrapper {
  display: flex;
  gap: 24px;

  @include maxMediaWidth(sm) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
  }
}
