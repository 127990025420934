@use 'src/css/breakpoint.scss' as *;

.gradientBox {
  position: relative;
  min-width: 160px;
  width: 100%;
  border-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border-radius: inherit;
    background: var(--market-data-border-gradient);
  }

  @include maxMediaWidth(lg) {
    min-width: 120px;
  }

  @include maxMediaWidth(sm) {
    min-width: 160px;
  }
}

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 18px 15px 17px;
  background: var(--main-dark-color);
  border-radius: inherit;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background: var(--color-primary-alpha-10);
    z-index: -1;
  }
}

.title,
.value {
  color: var(--secondary-text-color);
}

.title {
  font: var(--font-h11);
  margin-bottom: 5px;
}

.value {
  font: var(--font-h6);

  @include maxMediaWidth(lg) {
    font: var(--font-h7);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h6);
  }
}
