@use 'src/css/breakpoint.scss' as *;

.indexSection {
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px 0;

    @include maxMediaWidth(lg) {
      padding: 30px 0;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 0;
    }

    .titleContainer {
      @include maxMediaWidth(lg) {
        padding: 0 55px;
      }

      @include maxMediaWidth(sm) {
        padding: 0 15px;
      }
    }
  }
}

.title {
  padding-bottom: 50px;

  @include maxMediaWidth(lg) {
    padding-bottom: 38px;
  }

  @include maxMediaWidth(sm) {
    padding-bottom: 25px;
  }
}

.grid {
  display: grid;
  grid-column-gap: 30px;

  @include maxMediaWidth(sm) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 30px;
  }
}

.wrapSlider {
  max-width: var(--max-content-width);
  width: 100%;
  padding: 0 70px;
  margin: 0 auto;

  @include maxMediaWidth(lg) {
    padding: 0;
  }
}

.slider {
  :global(.swiper-wrapper) {
    align-items: stretch;
  }

  :global(.swiper-slide) {
    display: flex;
    justify-content: center;
    height: auto;
    width: 300px;
    min-width: 300px;
  }
}

.controlsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @include maxMediaWidth(lg) {
    display: none;
  }

  @include maxMediaWidth(sm) {
    display: flex;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  font: var(--font-h8);
  color: var(--pagination-numbers-dark-color);

  :global(.swiper-pagination-current) {
    color: var(--secondary-dark-color);
  }

  &:global(.swiper-pagination-disabled),
  &:global(.swiper-pagination-hidden) {
    display: none;
  }
}

.prevControl,
.nextControl {
  @include maxMediaWidth(sm) {
    display: none;
  }
}
