@use 'src/css/breakpoint.scss' as *;

.list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @include maxMediaWidth(lg) {
    column-gap: 16px;
  }

  @include maxMediaWidth(sm) {
    margin-bottom: 20px;
    gap: 8px;
  }
}

.listItem {
  display: flex;
  gap: 24px;
  font: var(--font-h9);
  white-space: nowrap;

  @include maxMediaWidth(lg) {
    gap: 16px;
  }

  @include maxMediaWidth(sm) {
    gap: 8px;
  }
}

.lastItem {
  color: var(--active-breadcrumb-color);
}

.breadcrumb {
  color: var(--color-foreground);

  &:hover {
    color: var(--breadcrumb-hover-color);
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.arrowIcon {
  transform: rotate(180deg);
  color: var(--footer-link-icon-color);
}
