.paymentProvidersContainer {
  display: grid;
  gap: 4px;
  font-size: 14px;
}

.paymentProvidersLabel {
  font: var(--font-h11);
  color: var(--color-foreground);
}

.paymentProvidersListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.paymentProviderItem {
  display: grid;
  gap: 10px;
  min-width: 152px;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: var(--color-primary-alpha-20);
  color: inherit;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  min-height: 48px;
  font-weight: 500;
  cursor: pointer;

  &[aria-checked='true'] {
    border: 1px solid var(--color-primary);
  }
}

.paymentProvidersError {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: var(--bleu-de-france-a20);
  min-height: 70px;
  min-width: 280px;
  max-width: 480px;

  .errorText {
    color: inherit;
  }

  .errorIcon {
    color: var(--providers-error-color);
    flex-shrink: 0;
  }
}

.skeletonButton {
  align-items: center;
  animation: var(--skeleton-animation);
  pointer-events: none;

  .skeletonText {
    display: block;
    height: 10px;
    width: 56px;
    border-radius: 2px;
    background: var(--skeleton-gradient);

    &:last-child {
      height: 14px;
      width: 75px;
    }
  }
}
