.checkboxField {
  display: flex;
  align-items: center;
  gap: 12px;
  user-select: none;
}

.checkbox {
  display: grid;
  place-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: var(--color-primary);
  border: 1px solid var(--color-primary-alpha-20);
  background-color: var(--color-primary-alpha-10);
  border-radius: 4px;
  transition: var(--transition-rapid);
  transition-property: border-color, background-color;
}

.checkbox[aria-checked='true'] {
  border-color: var(--color-primary);
  background-color: var(--color-primary-alpha-20);
}

.checkboxLabel {
  user-select: none;
  font: var(--font-h11);
}

.checkboxLabel a {
  color: var(--color-secondary);
  text-decoration: underline;
  transition: none;
}
