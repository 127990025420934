@use 'src/css/breakpoint.scss' as *;

.indexSection {
  margin: 130px auto;

  @include maxMediaWidth(lg) {
    margin: 60px auto;
  }

  @include maxMediaWidth(sm) {
    margin: 50px auto;
  }
}

.title {
  padding-bottom: 50px;

  @include maxMediaWidth(lg) {
    padding-bottom: 38px;
  }

  @include maxMediaWidth(sm) {
    padding-bottom: 25px;
  }
}

.customTooltip {
  padding: 10px;
  background: var(--chart-tooltip-bg-color);
  border: 1px solid var(--chart-tooltip-border-color);
  border-radius: 4px;
}

.label,
.value {
  margin: 0;
  font: var(--font-chart);
  letter-spacing: 0.15px;
  color: var(--chart-tooltip-text-color);
}

.value {
  color: var(--light-neutral-text-color);
}

.ticker {
  text-transform: capitalize;
}

.dot {
  width: 9px;
  height: 9px;
  margin-right: 5px;
  background: var(--chart-tooltip-dot-dark-color);
  border-radius: 50%;
}

.legendItem {
  display: flex;
  align-items: center;
  font: var(--font-chart);
  color: var(--secondary-text-color);
  stroke: var(--chart-tooltip-dot-dark-color);
}
