@use 'src/css/breakpoint.scss' as *;

.wrap {
  position: relative;
  color: var(--secondary-text-color);
  background: var(--color-primary-alpha-10);
  border-radius: 8px;
  padding: 30px 33px 30px 25px;
  overflow: hidden;
}

.title {
  margin-bottom: 10px;
  font: var(--font-h7);

  @include maxMediaWidth(lg) {
    font: var(--font-h7-md);
  }
}

.text {
  margin: 0;
  font: var(--font-h9);

  @include maxMediaWidth(lg) {
    font: var(--font-h8-md);
  }
}

.step {
  position: absolute;
  bottom: 0;
  right: -14px;
  font-size: 136px;
  font-weight: var(--weight-bold);
  line-height: 100px;
  letter-spacing: 0.015em;

  background-image: linear-gradient(
    248deg,
    rgba(255, 255, 255, 0.08) 7.98%,
    rgba(255, 255, 255, 0) 92.53%
  );
  background-clip: text;
  color: transparent;
}
