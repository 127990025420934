@use 'src/css/breakpoint.scss' as *;

.card {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  background: var(--wallet-card-gradient);
  border-radius: 24px;
  position: relative;
  overflow: hidden;
}

.cardContent {
  padding: 64px 16px 16px;
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  gap: 28px;
  align-items: center;
  justify-content: space-between;

  @include maxMediaWidth(sm) {
    padding: 48px 16px 32px;
    gap: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &.mediaContainer {
    gap: 24px;

    @include maxMediaWidth(sm) {
      gap: 20px;
    }
  }
}

.description {
  font: var(--font-h11);
  color: var(--color-foreground);
  text-align: center;
  margin: 0;

  @include maxMediaWidth(sm) {
    font: var(--font-h13);
  }
}

.iconsWrapper {
  display: flex;
  gap: 12px;
}

.appIcon {
  @include maxMediaWidth(sm) {
    width: 16px;
    height: 16px;
  }
}

.imgWrap {
  position: relative;
  height: 316px;
  min-height: 316px;
  width: 100%;
  object-fit: cover;

  .backgroundImage {
    width: 100%;
    height: 316px;
    z-index: 0;
  }

  .image {
    object-position: bottom;
    object-fit: contain;
    z-index: 1;
  }

  @include maxMediaWidth(sm) {
    display: none;
  }
}

.createBtn {
  text-transform: none;
  font: var(--font-h12);
  color: var(--main-dark-color);

  &:hover {
    color: var(--main-dark-color);
    background-color: var(--color-background-contrast);
  }
}

.recommendationBadge {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
  font: var(--font-h11);
  border-radius: 16px;
  background-color: var(--color-primary-light);
  color: var(--color-primary-dark);

  position: absolute;
  right: 16px;
  top: 16px;

  @include maxMediaWidth(sm) {
    font: var(--font-h13);
  }
}
