@use 'src/css/breakpoint.scss' as *;

.indexSection {
  max-width: 1274px;
  padding: 0 70px;
  margin: 70px auto 80px;

  @include maxMediaWidth(lg) {
    padding: 0;
  }

  @include maxMediaWidth(sm) {
    margin: 70px 15px 40px;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px;

    @include maxMediaWidth(lg) {
      padding: 30px 0;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 15px;
    }
  }
}

.innerContainer {
  padding: 0;

  @include maxMediaWidth(sm) {
    padding-right: 0;
    padding-left: 0;
  }
}

.grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  @include maxMediaWidth(lg) {
    grid-template-columns: 1fr;
  }
}

.title {
  text-align: center;
  padding-bottom: 45px;

  @include maxMediaWidth(lg) {
    padding-bottom: 38px;
  }

  @include maxMediaWidth(sm) {
    text-align: left;
    padding-bottom: 25px;
  }
}

.slider {
  :global(.swiper-wrapper) {
    align-items: stretch;
  }

  :global(.swiper-slide) {
    display: flex;
    justify-content: center;
    height: auto;
  }
}

.controlsWrapper {
  display: none;

  @include maxMediaWidth(sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  font: var(--font-h8);
  color: var(--pagination-numbers-dark-color);

  :global(.swiper-pagination-current) {
    color: var(--secondary-dark-color);
  }

  &:global(.swiper-pagination-disabled),
  &:global(.swiper-pagination-hidden) {
    display: none;
  }
}

.prevControl,
.nextControl {
  @include minMediaWidth(lg) {
    display: block;
  }
}
